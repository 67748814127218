import { Institution } from "@app/models/institution";
import { Role, RoleInstitution, RoleType } from "@app/models/role";

export class User {
    uid!: number;
    nome!: string;
    cognome!: string;
    cf!: string;
    entiRuoli!: RoleInstitution[];
    utentiInfo?: AdditionalInfo;
    dipendenteEnte?: boolean;

    constructor(init?: Partial<User>) {
        Object.assign(this, init);
    }
}

export class UserList implements Iterable<User> {
    users: User[] = [];

    constructor(users?: User[]) {
        if (users) this.users = users;
    }

    [Symbol.iterator](): Iterator<User, any, undefined> {
        let index = 0;
        const users = this.users;

        return {
            next(): IteratorResult<User> {
                if (index < users.length) return { value: users[index++], done: false };
                else return { value: undefined as unknown as User, done: true };
            },
        };
    }
}

export class AdditionalInfo {
    albo!: string;
    comune!: string;
    indirizzo!: string;
    mail!: string;
    numeroIscrizione!: string;
    provinciaAlbo!: number | string;
    telefono!: string;
    uid!: number;
    abilitazioneProfessionale?: string;
    abilitazioneProfessionaleData?: string;
}

export class UserLogin {
    nome!: string;
    cognome!: string;
    cf!: string;
    utenteEnteRuolo!: string;
}

export class LoggedUser {
    utente?: User;
    utenteEnteRuolo!: RoleInstitution;
    cambioRuolo!: boolean;

    constructor(init?: Partial<LoggedUser>) {
        Object.assign(this, init);
    }

    get isTechnician() {
        return this.utenteEnteRuolo.ruolo === RoleType.Technician;
    }
}

export class LoggedUserRole {
    uid!: number;
    ente!: Institution;
    ruolo!: Role;

    get isTechnician() {
        return this.ruolo.uid === RoleType.Technician;
    }
}
