import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, concatMap, map, Observable, of, switchMap, tap } from "rxjs";
import { Jwt } from "@app/models/jwt";
import { LoggedUser, LoggedUserRole, UserLogin } from "@app/models/user";
import { ResultList } from "../models/resultList";

const TOKEN_KEY = "auth-token";
const REFRESHTOKEN_KEY = "auth-refreshtoken";
const AM_TOKEN_KEY = "auth-am-token";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    servicesAuthUrl = environment.servicesAuthUrl;
    accessManagerUserUrl = `${environment.accessmanager.basepath}${environment.accessmanager.userinfoUri}`;
    accessManagerLogoutUrl = `${environment.accessmanager.basepath}${environment.accessmanager.logoutUri}`;
    private isUserLogged = false;
    private userSubject: BehaviorSubject<LoggedUser> = new BehaviorSubject<LoggedUser>(new LoggedUser());
    user$: Observable<LoggedUser> = this.userSubject.asObservable();

    constructor(private http: HttpClient) {}

    get isUserLoggedIn() {
        this.isUserLogged = !!localStorage.getItem(TOKEN_KEY);
        return this.isUserLogged;
    }

    login(data: UserLogin) {
        const httpCaller = data.utenteEnteRuolo
            ? this.http.put<Jwt>(`${this.servicesAuthUrl}/login/ruolo/${data.utenteEnteRuolo}`, {})
            : this.http.post<Jwt>(`${this.servicesAuthUrl}/login`, data);

        return httpCaller.pipe(
            tap({
                next: (payload: Jwt) => {
                    this.logout(false);
                    this.saveToken(payload.accessToken);
                    this.saveRefreshToken(payload.refreshToken);
                    this.getUser(true);
                },
            })
        );
    }

    refreshToken() {
        return this.http.post<Jwt>(`${this.servicesAuthUrl}/refresh`, {}).pipe(
            tap({
                next: (payload: Jwt) => {
                    this.saveToken(payload.accessToken);
                    this.saveRefreshToken(payload.refreshToken);
                },
            })
        );
    }

    logout(clear = true) {
        clear && localStorage.clear();
        this.userSubject.next(new LoggedUser());
        this.isUserLogged = false;
    }

    getUser(force?: boolean) {
        return this.userSubject.pipe(
            concatMap((user) => {
                if ((!user.utente || force) && this.getToken()) {
                    return this.http.get<LoggedUser>(`${this.servicesAuthUrl}/utente`, {}).pipe(
                        tap((res) => {
                            this.userSubject.next(res);
                        }),
                        switchMap(() => this.user$)
                    );
                } else return of(user);
            })
        );
    }

    setUser(loggedUser: LoggedUser): void {
        this.userSubject.next(loggedUser);
    }

    getUserRoles() {
        return this.http.post<ResultList<LoggedUserRole>>(`${this.servicesAuthUrl}/login/ruoli`, {});
    }

    reloadUser() {
        return this.http.post<Jwt>(`${this.servicesAuthUrl}/reload`, {}).pipe(
            tap({
                next: (payload: Jwt) => {
                    this.saveToken(payload.accessToken);
                    this.saveRefreshToken(payload.refreshToken);
                },
            })
        );
    }

    hasAllQualifications(requiredQualifications: number[]): Observable<boolean> {
        return this.getUser().pipe(
            map((loggedUser: LoggedUser) => {
                if (!requiredQualifications || requiredQualifications.length === 0) {
                    return true;
                }
                const userQualifications = loggedUser.utenteEnteRuolo.utentiAbilitazioni.map((x) => x.abilitazione);
                return requiredQualifications.every((qualification) => userQualifications.includes(qualification));
            })
        );
    }

    getToken() {
        return this.isUserLoggedIn ? localStorage.getItem(TOKEN_KEY) : "";
    }

    getRefreshToken() {
        return localStorage.getItem(REFRESHTOKEN_KEY);
    }

    getLocalAccessManagerToken() {
        return this.isUserLoggedIn ? localStorage.getItem(AM_TOKEN_KEY) : "";
    }

    saveToken(token: string): void {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.setItem(TOKEN_KEY, token);
    }

    saveAccessManagerToken(token: string): void {
        localStorage.removeItem(AM_TOKEN_KEY);
        localStorage.setItem(AM_TOKEN_KEY, token);
    }

    saveRefreshToken(token: string): void {
        localStorage.removeItem(REFRESHTOKEN_KEY);
        localStorage.setItem(REFRESHTOKEN_KEY, token);
    }

    getAccessManagerToken(code: string): Observable<Jwt> {
        const params = new HttpParams().set("codice", code);
        return this.http.get<Jwt>(`${this.servicesAuthUrl}/accessmanager/token`, { params }).pipe(
            tap({
                next: (payload: Jwt) => {
                    this.saveAccessManagerToken(payload.accessToken);
                },
            })
        );
    }

    getAccessManagerUser(token: Jwt): Observable<any> {
        const headers = new HttpHeaders().set("Authorization", `Bearer ${token.accessToken}`);
        return this.http.get<any>(this.accessManagerUserUrl, { headers });
    }

    logoutAccessManager(): Observable<any> {
        const headers = new HttpHeaders().set("Authorization", `Bearer ${this.getLocalAccessManagerToken()}`);
        return this.http.delete<any>(this.accessManagerLogoutUrl, { headers });
    }
}
