import { Injectable } from "@angular/core";
import { Municipality, Province, Purpose } from "@app/models/deco";
import { Institution } from "@app/models/institution";
import { ResultList } from "@app/models/resultList";
import { Qualification, QualificationType, Role } from "@app/models/role";
import { DecoService } from "@app/services/deco.service";
import { RoleService } from "@app/services/role.service";
import { SelectControlOption } from "design-angular-kit";
import { BehaviorSubject, Observable, of, switchMap, tap } from "rxjs";
import { AuthService } from "../services/auth.service";
import { State } from "@app/models/folder";

@Injectable({
    providedIn: "root",
})
export class DecoStoreService {
    private municipalitiesSubject: BehaviorSubject<SelectControlOption<number>[]> = new BehaviorSubject<
        SelectControlOption<number>[]
    >([]);
    private provincesSubject: BehaviorSubject<SelectControlOption<number>[]> = new BehaviorSubject<
        SelectControlOption<number>[]
    >([]);
    private institutionsSubject: BehaviorSubject<SelectControlOption<number>[]> = new BehaviorSubject<
        SelectControlOption<number>[]
    >([]);
    private institutionTypesSubject: BehaviorSubject<SelectControlOption<number>[]> = new BehaviorSubject<
        SelectControlOption<number>[]
    >([]);
    private qualificationsSubject: BehaviorSubject<ResultList<Qualification>> = new BehaviorSubject<
        ResultList<Qualification>
    >(new ResultList());
    private rolesSubject: BehaviorSubject<SelectControlOption<number>[]> = new BehaviorSubject<
        SelectControlOption<number>[]
    >([]);
    private purposesSubject: BehaviorSubject<SelectControlOption<number>[]> = new BehaviorSubject<
        SelectControlOption<number>[]
    >([]);
    private statesSubject: BehaviorSubject<SelectControlOption<number>[]> = new BehaviorSubject<
        SelectControlOption<number>[]
    >([]);
    municipalities$: Observable<SelectControlOption<number>[]> = this.municipalitiesSubject.asObservable();
    provinces$: Observable<SelectControlOption<number>[]> = this.provincesSubject.asObservable();
    institutions$: Observable<SelectControlOption<number>[]> = this.institutionsSubject.asObservable();
    institutionTypes$: Observable<SelectControlOption<number>[]> = this.institutionTypesSubject.asObservable();
    quolifications$: Observable<ResultList<Qualification>> = this.qualificationsSubject.asObservable();
    roles$: Observable<SelectControlOption<number>[]> = this.rolesSubject.asObservable();
    purposes$: Observable<SelectControlOption<number>[]> = this.purposesSubject.asObservable();
    states$: Observable<SelectControlOption<number>[]> = this.statesSubject.asObservable();

    constructor(private decoService: DecoService, private roleService: RoleService, private authService: AuthService) {}

    getMunicipalities(): Observable<SelectControlOption<number>[]> {
        return this.municipalitiesSubject.pipe(
            switchMap((municipalities) => {
                if (municipalities.length === 0) {
                    return this.decoService.getAllMunicipalities().pipe(
                        tap((res) => {
                            const options = res.risultati.map((x: Municipality) => ({ value: x.uid, text: x.nome }));
                            this.municipalitiesSubject.next(options);
                        }),
                        switchMap(() => this.municipalities$)
                    );
                } else return of(municipalities);
            })
        );
    }

    getProvinces(): Observable<SelectControlOption<number>[]> {
        return this.provincesSubject.pipe(
            switchMap((provinces) => {
                if (provinces.length === 0) {
                    return this.decoService.getAllProvinces().pipe(
                        tap((res) => {
                            const options = res.risultati.map((x: Province) => ({ value: x.uid, text: x.sigla }));
                            this.provincesSubject.next(options);
                        }),
                        switchMap(() => this.provinces$)
                    );
                } else return of(provinces);
            })
        );
    }

    getInstitutions(): Observable<SelectControlOption<number>[]> {
        return this.institutionsSubject.pipe(
            switchMap((institutions) => {
                if (institutions.length === 0) {
                    return this.decoService.getAllInstitutions().pipe(
                        tap((res) => {
                            const options = res.risultati.map((x: Institution) => ({ value: x.uid, text: x.nome }));
                            this.institutionsSubject.next(options);
                        }),
                        switchMap(() => this.institutions$)
                    );
                } else return of(institutions);
            })
        );
    }

    setInstitutions(institutionList: SelectControlOption<number>[]): void {
        this.institutionsSubject.next(institutionList);
    }

    getInstitutionTypes(): Observable<SelectControlOption<number>[]> {
        return this.institutionTypesSubject.pipe(
            switchMap((institutionTypes) => {
                if (institutionTypes.length === 0) {
                    return this.decoService.getAllInstitutionTypes().pipe(
                        tap((res) => {
                            const options = res.risultati.map((x: Institution) => ({ value: x.uid, text: x.nome }));
                            this.institutionTypesSubject.next(options);
                        }),
                        switchMap(() => this.institutionTypes$)
                    );
                } else return of(institutionTypes);
            })
        );
    }

    getQualifications(): Observable<ResultList<Qualification>> {
        return this.qualificationsSubject.pipe(
            switchMap((quolifications) => {
                if (quolifications.risultati.length === 0) {
                    return this.roleService.getAllQualifications().pipe(
                        tap((res) => {
                            this.qualificationsSubject.next(res);
                        }),
                        switchMap(() => this.quolifications$)
                    );
                } else return of(quolifications);
            })
        );
    }

    setQualifications(qualificationList: ResultList<Qualification>): void {
        this.qualificationsSubject.next(qualificationList);
    }

    getRoles(all = false): Observable<SelectControlOption<number>[]> {
        return this.rolesSubject.pipe(
            switchMap((roles) => {
                if (roles.length === 0) {
                    return this.authService.hasAllQualifications([QualificationType.MANAGE_ROLES]).pipe(
                        switchMap((hasQualifications) => {
                            const callRoleService = hasQualifications
                                ? this.roleService.getAll(all)
                                : this.roleService.getProfileRoles();

                            return callRoleService.pipe(
                                tap((res) => {
                                    const options = res.risultati.map((x: Role) => ({ value: x.uid, text: x.nome }));
                                    this.rolesSubject.next(options);
                                }),
                                switchMap(() => this.roles$)
                            );
                        })
                    );
                } else {
                    return of(roles);
                }
            })
        );
    }

    updateRoles(): Observable<SelectControlOption<number>[]> {
        return this.roleService.getAll().pipe(
            tap((res) => {
                const options = res.risultati.map((x: Role) => ({ value: x.uid, text: x.nome }));
                this.rolesSubject.next(options);
            }),
            switchMap(() => this.roles$)
        );
    }

    setRoles(roleList: ResultList<Role>): void {
        this.rolesSubject.next(roleList.risultati.map((x: Role) => ({ value: x.uid, text: x.nome })));
    }

    getPurposes(): Observable<SelectControlOption<number>[]> {
        return this.purposesSubject.pipe(
            switchMap((purposes) => {
                if (purposes.length === 0) {
                    return this.decoService.getAllPurposes().pipe(
                        tap((res) => {
                            const options = res.risultati.map((x: Purpose) => ({ value: x.uid, text: x.nome }));
                            this.purposesSubject.next(options);
                        }),
                        switchMap(() => this.purposes$)
                    );
                } else return of(purposes);
            })
        );
    }

    getFolderStates(): Observable<SelectControlOption<number>[]> {
        return this.statesSubject.pipe(
            switchMap((states) => {
                if (states.length === 0) {
                    return this.decoService.getAllFolderStates().pipe(
                        tap((res) => {
                            const options = res.risultati.map((x: State) => ({ value: x.uid, text: x.nome }));
                            this.statesSubject.next(options);
                        }),
                        switchMap(() => this.states$)
                    );
                } else return of(states);
            })
        );
    }
}
